
import { Component, Vue, Prop } from 'vue-property-decorator';
import Anchor from '@/components/shared/Anchor.vue';
import { IpdcProduct } from '@govflanders/mbp-admin-panel-shared';
import ProductForm from '@/components/ipdc/ProductForm.vue';
import PageHeader from '@/components/shared/PageHeader.vue';
import PageHeaderAction from '@/components/shared/PageHeaderAction.vue';
import PageHeaderNavigationAction from '@/components/shared/PageHeaderNavigationAction.vue';
import { mixins } from 'vue-class-component';
import PendingChangesGuard from '@/utility/forms/PendingChangesGuard.vue';
import { VlFormValidationObserver } from '@govflanders/vl-ui-vue-components';
import { cloneDeep } from 'lodash';

@Component({
  components: {
    Anchor,
    ProductForm,
    PageHeader,
    PageHeaderAction,
    PageHeaderNavigationAction,
  },
})
export default class IpdcProductEdit extends mixins(PendingChangesGuard) {
  @Prop({
    type: String,
    required: true,
  })
  public id!: string;

  public valueLoading: boolean;

  public value: IpdcProduct = null;
  public initialValue: IpdcProduct = null;

  public created() {
    this.valueLoading = true;
    this.$store
      .dispatch('ipdcProduct/get', this.id)
      .then(product => {
        this.value = cloneDeep(product);
        this.initialValue = cloneDeep(product);
        this.valueLoading = false;
      })
      .catch(e => console.log(e));
  }

  public revert() {
    (this.$refs.observer as VlFormValidationObserver).reset();
    this.value = cloneDeep(this.initialValue);
  }

  public save() {
    this.$store
      .dispatch('ipdcProduct/update', this.value)
      .then(updatedValue => {
        this.value = updatedValue;
        this.initialValue = cloneDeep(updatedValue);
        this.$toast.success(this.$t('ipdc.update.success'));
      })
      .catch(e => {
        console.error(e);
        this.$toast.error(this.$t('ipdc.update.error'));
      });
  }
}


import _ from 'lodash';
import { Component, Watch } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import Anchor from '@/components/shared/Anchor.vue';
import PageHeader from '@/components/shared/PageHeader.vue';
import PageHeaderNavigationAction from '@/components/shared/PageHeaderNavigationAction.vue';
import SearchPageTemplate from '@/components/search-page/SearchPageTemplate.vue';
import {
  SearchFilterDefinitions,
  SearchFilterTypes,
  SearchPageMetaData,
  SearchQueryData,
} from '@/utility/definitions';
import { IpdcProductTheme } from '@govflanders/mbp-admin-panel-shared';
import RoleGuard from '@/utility/auth/RoleGuard.vue';
import { mixins } from 'vue-class-component';

@Component({
  components: {
    PageHeader,
    PageHeaderNavigationAction,
    Anchor,
    SearchPageTemplate,
  },
})
export default class MbpThemeSearch extends mixins(RoleGuard) {
  @Getter('ipdcProductThemeSearch/isFailed')
  isFailed!: boolean;
  @Getter('ipdcProductThemeSearch/isLoading')
  isLoading!: boolean;
  @Getter('ipdcProductThemeSearch/isSuccess')
  isSuccess!: boolean;
  @Getter('ipdcProductThemeSearch/results')
  results: IpdcProductTheme[] | null;
  @Getter('ipdcProductThemeSearch/pageMetaData')
  pageMetaData!: SearchPageMetaData | null;

  private offset = 0;

  mounted() {
    if (this.$route.query.offset) {
      this.offset = parseInt(`${this.$route.query.offset}`);
    }
  }

  private queryObject: SearchQueryData = {};

  public get query(): SearchQueryData {
    return this.queryObject;
  }

  public set query(value: SearchQueryData) {
    this.queryObject = _(value)
      .omitBy(_.isUndefined)
      .omitBy(_.isNull)
      .value();
    this.$router.push({ name: 'ipdc.theme', query: this.queryObject });
  }

  public async search(): Promise<void> {
    await this.$store.dispatch('ipdcProductThemeSearch/execute', this.query);
  }

  public get filters(): SearchFilterDefinitions {
    return [
      {
        type: SearchFilterTypes.Input,
        name: 'query',
        label: 'common.search.filters.query.label',
      },
    ];
  }

  @Watch('queryObject')
  private onQueryChange() {
    this.search();
  }

  private beforeRouteEnter(to, from, next) {
    next((vm: this) => {
      vm.queryObject = _(to.query)
        .omitBy(_.isUndefined)
        .omitBy(_.isNull)
        .value();
    });
  }

  private beforeRouteUpdate(to, from, next) {
    this.queryObject = _(to.query)
      .omitBy(_.isUndefined)
      .omitBy(_.isNull)
      .value();
    next();
  }
}

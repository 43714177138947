
import _ from 'lodash';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import Anchor from '@/components/shared/Anchor.vue';
import PageHeader from '@/components/shared/PageHeader.vue';
import PageHeaderNavigationAction from '@/components/shared/PageHeaderNavigationAction.vue';
import SearchPageTemplate from '@/components/search-page/SearchPageTemplate.vue';
import {
  SearchFilterDefinitions,
  SearchFilterTypes,
  SearchPageMetaData,
  SearchQueryData,
} from '@/utility/definitions';
import { IpdcProduct } from '@govflanders/mbp-admin-panel-shared';

@Component({
  components: {
    PageHeader,
    PageHeaderNavigationAction,
    Anchor,
    SearchPageTemplate,
  },
})
export default class IpdcProductSearch extends Vue {
  @Getter('ipdcProductSearch/isFailed')
  private isFailed!: boolean;
  @Getter('ipdcProductSearch/isLoading')
  private isLoading!: boolean;
  @Getter('ipdcProductSearch/isSuccess')
  private isSuccess!: boolean;
  @Getter('ipdcProductSearch/results')
  private results: IpdcProduct[] | null;
  @Getter('ipdcProductSearch/pageMetaData')
  private pageMetaData!: SearchPageMetaData | null;

  private offset = 0;

  mounted() {
    if (this.$route.query.offset) {
      this.offset = parseInt(`${this.$route.query.offset}`);
    }
  }

  private queryObject: SearchQueryData = {};

  public get query(): SearchQueryData {
    return this.queryObject;
  }

  public set query(value: SearchQueryData) {
    this.queryObject = _(value)
      .omitBy(_.isUndefined)
      .omitBy(_.isNull)
      .value();
    this.$router.push({ name: 'ipdc.product', query: this.queryObject });
  }

  public async search(): Promise<void> {
    await this.$store.dispatch('ipdcProductSearch/search', this.query);
  }

  public async refreshCache(): Promise<void> {
    await this.$store
      .dispatch('ipdcProductSearch/refreshCache')
      .then(response => {
        this.$toast.success(this.$t('ipdc.product-form.refreshDataSuccess'));
      })
      .catch(response => {
        this.$toast.error(
          `${this.$t('ipdc.product-form.refreshDataError')} error: ${response.error}`,
        );
      });
  }

  public get filters(): SearchFilterDefinitions {
    return [
      {
        type: SearchFilterTypes.Input,
        name: 'query',
        label: 'common.search.filters.query.label',
      },
    ];
  }

  @Watch('queryObject')
  private onQueryChange() {
    this.search();
  }

  private beforeRouteEnter(to, from, next) {
    next((vm: this) => {
      vm.queryObject = _(to.query)
        .omitBy(_.isUndefined)
        .omitBy(_.isNull)
        .value();
    });
  }

  private beforeRouteUpdate(to, from, next) {
    this.queryObject = _(to.query)
      .omitBy(_.isUndefined)
      .omitBy(_.isNull)
      .value();
    next();
  }
}
